<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="reader-body pt-0">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="api.isError" :api="api" />
          <!-- EOC -->
          <!-- <div class="pe-2 ps-3 app-title text-h5" v-if="!readerInstance">
            {{ book.name }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div> -->

          <div
            class="px-5 text-center d-flex align-center justify-center"
            v-if="!readerInstance"
            style="min-height: calc(100vh - 60px)"
          >
            <div class="text-center d-flex flex-column align-center">
              <div
                class="text-h6"
                style="font-family: 'MouseMemoirs', sans-serif !important"
                v-if="chapter.translatableSection"
              >
                {{ JSON.parse(chapter.translatableSection)[subjectLang] }}
              </div>
              <div
                class="text-h6 mb-6"
                style="font-family: 'MouseMemoirs', sans-serif !important"
                v-if="
                  chapter.translatableSection &&
                  settings.assistingLang == 'ON' &&
                  $_getLocale() != subjectLang &&
                  JSON.parse(chapter.translatableSection)[$_getLocale()]
                "
              >
                {{ JSON.parse(chapter.translatableSection)[$_getLocale()] }}
              </div>

              <div
                class="text-h3"
                style="font-family: 'MouseMemoirs', sans-serif !important"
                v-if="chapter.translatableName"
              >
                {{ JSON.parse(chapter.translatableName)[subjectLang] }}
              </div>
              <div
                class="text-h3"
                style="font-family: 'MouseMemoirs', sans-serif !important"
                v-if="
                  chapter.translatableName &&
                  settings.assistingLang == 'ON' &&
                  $_getLocale() != subjectLang &&
                  JSON.parse(chapter.translatableName)[$_getLocale()]
                "
              >
                {{ JSON.parse(chapter.translatableName)[$_getLocale()] }}
              </div>

              <v-btn
                style="font-family: 'MouseMemoirs', sans-serif !important"
                class="mt-4 text-h5 pulse"
                block
                color="green"
                rounded
                large
                dark
                @click="startGame"
                :loading="api.isLoading"
              >
                {{ $t("string.startReading") }}
              </v-btn>

              <div v-if="settings.debug">
                <v-divider class="mt-6"></v-divider>

                <v-btn
                  color="secondary"
                  class="mt-4"
                  dark
                  text
                  depressed
                  @click="skip()"
                >
                  Skip & Complete
                </v-btn>
              </div>
            </div>
          </div>
          <div
            :id="containerId"
            style="
              max-width: 480px;
              padding: 0;
              max-height: 100vh !important;
              margin: auto;
            "
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
const ABC = require("@/hamochiABC/reader/main");
export default {
  components: {
    // CloseBtn: () =>
    //   import(
    //     /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
    //   ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
    event: (state) => state.event.data,
  }),
  props: [
    //
  ],
  data: () => ({
    containerId: "game-container",
    readerInstance: null,
    book: {},
    chapter: {},
    star: 0,
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    steps: [],
    subjectLang: "en",
  }),
  async created() {
    let tempSettings = this.$_.cloneDeep(this.settings);
    tempSettings.assistingLang = "ON";
    this.$store.commit("updateSettings", tempSettings);
    this.$store.dispatch("resetABC");
    await this.openChapter();
    await this.openBook();
    if (this.book.subject == "Mandarin") {
      this.subjectLang = "zh";
    } else if (this.book.subject == "Malay") {
      this.subjectLang = "ms";
    } else {
      this.subjectLang = "en";
    }
  },
  mounted() {
    //
  },
  beforeDestroy() {
    let master = this.settings.audio.master;
    let music = this.settings.audio.music;
    this.$bgMusic.src = require("@/assets/sounds/music_bg_1.mp3");
    this.$bgMusic.volume = music * master;
    this.$bgMusic.play();
  },
  destroyed() {
    if (this.readerInstance) {
      this.readerInstance.destroy(true);
      this.readerInstance = null;
    }
  },
  methods: {
    async openChapter() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/open";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.chapter = resp.Chapter;
      };
      this.api.params = {
        chapterKey: this.$route.params.chapterKey,
      };
      await this.$api.fetch(this.api);
    },
    async readChapter() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/read";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.steps = resp.Book;
      };
      this.api.params = {
        chapterKey: this.$route.params.chapterKey,
      };
      await this.$api.fetch(this.api);
    },
    async openBook() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/open";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        this.book = resp.Book;
      };
      this.api.params = {
        bookKey: this.$route.params.bookKey,
      };
      await this.$api.fetch(this.api);
    },
    async saveChapter() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/chapter/player/save";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        chapterKey: this.$route.params.chapterKey,
        bookKey: this.$route.params.bookKey,
        topStar:
          this.chapter.PlayerChapter.length > 0
            ? this.chapter.PlayerChapter[0].topStar > this.star
              ? this.chapter.PlayerChapter[0].topStar
              : this.star
            : this.star,
        attempt:
          this.chapter.PlayerChapter.length > 0
            ? this.chapter.PlayerChapter[0].attempt + 1
            : 1,
      };
      await this.$api.fetch(this.api);
    },
    async saveBook() {
      this.api.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/abc/book/player/save";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        bookKey: this.$route.params.bookKey,
        totalStar:
          this.chapter.PlayerChapter.length > 0
            ? this.chapter.PlayerChapter[0].topStar > this.star
              ? this.book.PlayerBook[0].totalStar
              : this.book.PlayerBook[0].totalStar -
                this.chapter.PlayerChapter[0].topStar +
                this.star
            : this.book.PlayerBook[0].totalStar + this.star,
      };
      await this.$api.fetch(this.api);
    },
    async attempt() {
      this.api.url =
        this.$api.servers.log +
        "/api/v1/" +
        this.$_getLocale() +
        "/attempt/add";
      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
        this.api.error = null;
      };
      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        studentId: this.event.submission.studentId,
        eventId: this.event.submission.eventId,
        levelId: this.event.submission.levelId,
        mochiId: this.event.submission.mochiId,
        syllabusKey: this.event.submission.syllabusKey,
        chapterKey: this.event.submission.chapterKey,
        questionKey: this.event.submission.questionKey,
      };
      this.$api.fetch(this.api);
    },
    exit() {
      this.$router.push({
        name: "PageEventABCBookChapters",
        params: { bookKey: this.$route.params.bookKey },
      });
    },
    skip() {
      this.$router.push({
        name: "PageEventABCBookCompleted",
        params: {
          bookKey: this.$route.params.bookKey,
          chapterKey: this.$route.params.chapterKey,
        },
      });
    },
    async completeChapter() {
      await this.saveBook();
      await this.saveChapter();
      this.exit();
    },
    async startGame() {
      if (
        !this.settings.autoPlay ||
        (this.settings.autoPlay != "ON" && this.settings.autoPlay != "OFF")
      ) {
        let tempSettings = this.$_.cloneDeep(this.settings);
        tempSettings.autoPlay = "ON";
        this.$store.commit("updateSettings", tempSettings);
      }

      // if (
      //   !this.settings.assistingLang ||
      //   (this.settings.assistingLang != "ON" &&
      //     this.settings.assistingLang != "OFF")
      // ) {
      //   let tempSettings = this.$_.cloneDeep(this.settings);
      //   tempSettings.assistingLang = "ON";
      //   this.$store.commit("updateSettings", tempSettings);
      // }

      await this.readChapter();
      await this.attempt();
      this.readerInstance = ABC.launch(this.containerId);
      this.readerInstance.scene.start("LoadingScene", {
        avatar: this.avatarItem,
        steps: this.steps,
        autoPlay: this.settings.autoPlay == "ON",
        subjectLang: this.subjectLang,
        isEvent: true,
      });
      this.$bgMusic.pause();
      this.$bgMusic.src = null;
    },
  },
};
</script>

<style scoped>
.pulse:hover {
  animation: none;
}

.pulse {
  animation: beat 0.5s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.05);
  }
}

.reader-body {
  max-width: 480px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  background-color: #ffffff;
}

.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.list-card {
  padding: 5px;
  color: #785c3a;
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #fff9f1;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>