var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"reader-body pt-0"},[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),(!_vm.readerInstance)?_c('div',{staticClass:"px-5 text-center d-flex align-center justify-center",staticStyle:{"min-height":"calc(100vh - 60px)"}},[_c('div',{staticClass:"text-center d-flex flex-column align-center"},[(_vm.chapter.translatableSection)?_c('div',{staticClass:"text-h6",staticStyle:{"font-family":"'MouseMemoirs', sans-serif !important"}},[_vm._v(" "+_vm._s(JSON.parse(_vm.chapter.translatableSection)[_vm.subjectLang])+" ")]):_vm._e(),(
                _vm.chapter.translatableSection &&
                _vm.settings.assistingLang == 'ON' &&
                _vm.$_getLocale() != _vm.subjectLang &&
                JSON.parse(_vm.chapter.translatableSection)[_vm.$_getLocale()]
              )?_c('div',{staticClass:"text-h6 mb-6",staticStyle:{"font-family":"'MouseMemoirs', sans-serif !important"}},[_vm._v(" "+_vm._s(JSON.parse(_vm.chapter.translatableSection)[_vm.$_getLocale()])+" ")]):_vm._e(),(_vm.chapter.translatableName)?_c('div',{staticClass:"text-h3",staticStyle:{"font-family":"'MouseMemoirs', sans-serif !important"}},[_vm._v(" "+_vm._s(JSON.parse(_vm.chapter.translatableName)[_vm.subjectLang])+" ")]):_vm._e(),(
                _vm.chapter.translatableName &&
                _vm.settings.assistingLang == 'ON' &&
                _vm.$_getLocale() != _vm.subjectLang &&
                JSON.parse(_vm.chapter.translatableName)[_vm.$_getLocale()]
              )?_c('div',{staticClass:"text-h3",staticStyle:{"font-family":"'MouseMemoirs', sans-serif !important"}},[_vm._v(" "+_vm._s(JSON.parse(_vm.chapter.translatableName)[_vm.$_getLocale()])+" ")]):_vm._e(),_c('v-btn',{staticClass:"mt-4 text-h5 pulse",staticStyle:{"font-family":"'MouseMemoirs', sans-serif !important"},attrs:{"block":"","color":"green","rounded":"","large":"","dark":"","loading":_vm.api.isLoading},on:{"click":_vm.startGame}},[_vm._v(" "+_vm._s(_vm.$t("string.startReading"))+" ")]),(_vm.settings.debug)?_c('div',[_c('v-divider',{staticClass:"mt-6"}),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"secondary","dark":"","text":"","depressed":""},on:{"click":function($event){return _vm.skip()}}},[_vm._v(" Skip & Complete ")])],1):_vm._e()],1)]):_vm._e(),_c('div',{staticStyle:{"max-width":"480px","padding":"0","max-height":"100vh !important","margin":"auto"},attrs:{"id":_vm.containerId}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }